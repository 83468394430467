import React from 'react'
import {Grid, Typography, Button} from '@mui/material';
import glossier from '../../images/glossier-1.svg'
import goPro from '../../images/gopro-logo-1.png'
import lyft from '../../images/lyft.svg'
import mab from '../../images/Maybelline-Logo.svg.png'
import vice from '../../images/Vice_Media-Logo.wine.png'


function TrustedBrands() {
  return (
    <Grid item container xs={12} style={{backgroundColor:'lightgray', display:'flex', flexFlow:'column', alignItems:'center', justifyContent:'center'}}>
        <Typography variant='h2' style={{fontSize:35, marginBottom:10, fontWeight:'bold', paddingTop:70}}>8,000+ Influencers and Brands</Typography>
        <Typography style={{textAlign:'center', marginBottom:10}}>Trusted and used for organic social media follower growth and promotion by <br/>leading influencers and brands.</Typography>
        <Grid item container xs={12} md={7} style={{display:'flex', flexFlow:'row', justifyContent:'space-evenly', height:'fit-content', paddingBottom:70}}>
            <img src={lyft} alt='' style={{width:'130px', objectFit:'contain', opacity:'80%'}}/>
            <img src={vice} alt='' style={{width:'130px', objectFit:'contain', opacity:'70%'}}/>
            <img src={goPro} alt='' style={{width:'130px', objectFit:'contain'}}/>
            <img src={mab} alt='' style={{width:'130px', objectFit:'contain', opacity:'80%'}}/>
            <img src={glossier} alt='' style={{width:'130px', objectFit:'contain', opacity:'70%'}}/>
        </Grid>

        

    </Grid>
  )
}

export default TrustedBrands