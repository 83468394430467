import React, { useState }  from 'react'
import {Grid, Typography, Button, Card, CardActions, CardContent, CardMedia} from '@mui/material';
import ViewsCard from '../ViewsCard';
import FollowerCards from '../FollowerCards';
import CheckoutForm from '../CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const {REACT_APP_QQTUBE_KEY, REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST, REACT_APP_STRIPE_SECRET_KEY_TEST} = process.env


function Plans({REACT_APP_STRIPE_PUBLISHABLE_KEY}) {
    const stripePromisTest=loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY)
    const [viewsSelected, setViewsSelected] = useState(false)
    const [followersSelected, setFollowersSelected] = useState(false)
    const [selectedPlatform, setSelectedPlatform] = useState('')
    const [selectedPlan, setSelectedPlan] = useState('')

    // console.log(REACT_APP_STRIPE_PUBLISHABLE_KEY)


    const handleViewSelection = () => {
        setViewsSelected(true)
        if(followersSelected){
            setFollowersSelected(false)
        }

    }

    const handleFollowSelection = () => {
        setFollowersSelected(true)
        if(viewsSelected){
            setViewsSelected(false)
        }
    }


  return (
    <Grid item container xs={12} style={{height:'fit-content', padding:'60px 0px'}}>
        <Grid item container style={{maxWidth:1000, display:'flex', flexFlow:'column', paddingTop:60, margin:'0px auto', alignItems:'center', textAlign:'center'}}>
          
            <Typography style={{padding:10, backgroundColor:'blue', width:'fit-content', color:'white', fontWeight:'bold'}}>Buy Real Followers</Typography>
     
            {(selectedPlatform==='' ) &&
            <>
            <Typography variant='h2' style={{fontSize:40, fontWeight:'bold', padding:'40px 0px'}}>Our plans.</Typography>
            <Typography style={{textAlign:'center'}}>
                Choose the Standard package if you just want a credibility boost on your account. 
                <br/>
                Choose the Premiumpackage if you want to simulate a high-quality, engaging Instagram account.
            </Typography>
            </>
            }
            

        {(!viewsSelected && !followersSelected ) &&
        <Grid item container xs={12} style={{display:'flex', flexFlow:'row', justifyContent:'center', marginTop:50}}>
            <Button variant={viewsSelected ? 'contained' : 'outlined'} color='primary' style={{width:340, borderWidth:2, fontWeight:'bold', borderRadius:10, height:70, marginRight:10}}
            onClick={handleViewSelection}>
                    Increase Views & Engagements 
            </Button>
            <Button variant={followersSelected ? 'contained' : 'outlined'} color='primary' style={{width:340, borderWidth:2, fontWeight:'bold', borderRadius:10, height:70}}
            onClick={handleFollowSelection}>
                Gain Followers
            </Button>
        </Grid>
        }


        {((viewsSelected || followersSelected) && selectedPlatform === '') ?
            <Grid item container xs={12} style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', marginTop:40}}>
                <Typography style={{width:'inherit', textAlign:'center', fontWeight:'bold', fontSize:40, marginBottom:40}}> Select A Platform</Typography>
                <img onClick={()=> setSelectedPlatform('FB')} style={{width:100, height:100, objectFit:'contain', padding:10}} src="https://goodwinch.org/wp-content/uploads/FB-Logo.png" alt=""/>
                <img onClick={()=> setSelectedPlatform('IG')} style={{width:100, height:100, objectFit:'contain', padding:10}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png" alt=""/>
                <img onClick={()=> setSelectedPlatform('YT')} style={{width:100, height:100, objectFit:'contain', padding:10}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png" alt=""/>
                <img onClick={()=> setSelectedPlatform('TT')} style={{width:100, height:100, objectFit:'contain', padding:10}} src="https://seeklogo.com/images/T/tiktok-share-icon-black-logo-29FFD062A0-seeklogo.com.png" alt=""/>
            </Grid>
            :
            null
        }

       
       {((viewsSelected || !followersSelected) && selectedPlatform!=="" && selectedPlan === '') && 
        <ViewsCard selectedPlatform={selectedPlatform} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan}/>
       }

       {(followersSelected && selectedPlatform!=="" && selectedPlan === '') &&
        <FollowerCards selectedPlatform={selectedPlatform} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan}/>
       }

       {selectedPlan !== '' &&
       <Elements stripe={stripePromisTest}>
           <CheckoutForm selectedPlan={selectedPlan} vs={viewsSelected} fs={followersSelected} setSelectedPlan={setSelectedPlan} setSelectedPlatform={setSelectedPlatform} setViewsSelected={setViewsSelected} setFollowersSelected={setFollowersSelected}
           QQTUBE_API_KEY={REACT_APP_QQTUBE_KEY} REACT_APP_STRIPE_PUBLISHABLE_KEY={REACT_APP_STRIPE_PUBLISHABLE_KEY} REACT_APP_STRIPE_SECRET_KEY_TEST={REACT_APP_STRIPE_SECRET_KEY_TEST}
           />
       </Elements>
       }

       {(followersSelected || viewsSelected) && 
       <Grid item container xs={12} style={{marginTop:30, display:'flex', flexDirection:'row', justifyContent:'center'}}>
        {selectedPlatform !== '' &&
        <Button variant='outlined' color='primary' style={{width:340, borderWidth:2, fontWeight:'bold', borderRadius:10, height:70, marginRight:10}}
        onClick={()=> {setSelectedPlatform(''); setSelectedPlan('')}}>
               Change Platform
        </Button>
        }
        <Button variant='outlined' color='primary' style={{width:340, borderWidth:2, fontWeight:'bold', borderRadius:10, height:70}}
        onClick={()=> {setFollowersSelected(false); setViewsSelected(false); setSelectedPlatform(''); setSelectedPlan('')}}>
                Start Over 
        </Button>
       </Grid>
       }

        </Grid>

    </Grid>
  )
}


export default Plans