import React from "react";


export const packages = [
    {platform: 'FB',
        plans: [
            {min:[1000, 100], max:[3000, 200], qqIds:[907, 1185], planType:'views', name:'FB Views & Engagments pkg 1', details:'1 post w/ 1k - 3k likes, 100 - 200 shares', increments: [100,100]  , img:'https://goodwinch.org/wp-content/uploads/FB-Logo.png', price:'19.99'},
            {banner: 'Most Popular',min:[3500, 200], max:[7500, 300], qqIds:[907, 1185], planType:'views', name:'FB Views & Engagments pkg 2', details:'1 post w/ 3.5k - 7.5k likes, 200 - 300 shares', increments: [100,100]  , img:'https://goodwinch.org/wp-content/uploads/FB-Logo.png', price:'37.99'},
            {banner: 'Best Value', min:[8000, 300 ], max:[15000, 400], qqIds:[907, 1185], planType:'views', name:'FB Views & Engagments pkg 3', details:'1 post w/ 8k - 15k likes, 300 - 400 shares', increments: [100,100]  , img:'https://goodwinch.org/wp-content/uploads/FB-Logo.png', price:'44.99'},
            {banner: 'Good Deal!', min:[1500, 100, 100], max:[3000, 200, 200], qqIds:[1127, 907, 1185,], planType:'views', name:'FB Views & Engagments pkg 4', details:'1 post w/ 1.5k - 3k video views, 100 - 200 likes , 100 - 200 shares', increments: [500,100,100]  , img:'https://goodwinch.org/wp-content/uploads/FB-Logo.png', price:'26.99'},
            {banner: 'Great Package!', min:[3500, 200, 200], max:[7000, 300, 300], qqIds:[1127, 907, 1185], planType:'views', name:'FB Views & Engagments pkg 5', details:'1 post w/ 3.5k - 7k video views, 200 - 300 likes , 200 - 300 shares', increments: [500,100,100]  , img:'https://goodwinch.org/wp-content/uploads/FB-Logo.png', price:'44.99'},
            {banner: 'Best Value!', min:[7500, 300, 300], max:[10000, 400, 400], qqIds:[1127, 907, 1185], planType:'views', name:'FB Views & Engagments pkg 6', details:'1 post w/ 7.5k - 10k video views, 300 - 400 likes , 300 - 400 shares', increments: [500,100,100]  , img:'https://goodwinch.org/wp-content/uploads/FB-Logo.png', price:'59.99'},
            {min:[1000], max:[3000], qqIds:[1073], planType: 'follows', name:'FB Follows pkg 1', details:'1k-3k page follows', increments: [100]  , img:'https://goodwinch.org/wp-content/uploads/FB-Logo.png', price:'54.99'},
            {banner: 'Most Popular', min:[5000], max:[10000], qqIds:[1073], planType:'follows', name:'FB Follows pkg 2', details:'5k - 10k page follows', increments: [100]  , img:'https://goodwinch.org/wp-content/uploads/FB-Logo.png', price:'109.99'},
            {banner: 'Best Value!', min:[10500], max:[15000], qqIds:[1073], planType:'follows', name:'FB Follows pkg 3', details:'10.5k - 15k page follows', increments: [100]  , img:'https://goodwinch.org/wp-content/uploads/FB-Logo.png', price:'159.99'},
        ]
    },
    {platform: 'IG',
        plans: [
            {min:[1000], max:[3000], qqIds:[1071], planType:'views', name:'IG Views & Engagements pkg 1', details:'1 post w/ 1k - 3k likes', increments: [25]  , img:'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png', price:'9.99'},
            {min:[3500], max:[7500], qqIds:[1071], planType:'views', name:'IG Views & Engagements pkg 2', details:'1 post w/ 3.5k - 7.5k likes', increments: [25]  , img:'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png', price:'17.99', banner:'Most Popular'},
            {min:[8000], max:[15000], qqIds:[1071], planType:'views', name:'IG Views & Engagements pkg 3', details:'1 post w/ 8k - 15k likes', increments: [25]  , img:'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png', price:'29.99', banner:'Best Deal!'},
            {min:[1500, 150], max:[3500, 200], qqIds:[1080, 1071], planType:'views', name:'IG Views & Engagements pkg 4', details:'1 post w/ 1.5k - 3.5k views, 150 - 200 likes', increments: [100, 25]  , img:'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png', price:'14.99'},
            {min:[4000, 200], max:[8000, 350], qqIds:[1080, 1071], planType:'views', name:'IG Views & Engagements pkg 5', details:'1 post w/ 4k - 8k views, 200 - 350 likes', increments: [100, 25]  , img:'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png', price:'24.99', banner:'Good Deal'},
            {min:[8500, 250], max:[15000, 500], qqIds:[1080, 1071], planType:'views', name:'IG Views & Engagements pkg 6', details:'1 post w/ 8.5k - 15k views, 250 - 500 likes', increments: [100, 25]  , img:'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png', price:'39.99', banner:'Best Value!'},
            {min:[1500], max:[3500], qqIds:[628], planType:'follows', name:'IG Follows pkg 1', details:'1.5k - 3.5k new followers', increments: [10]  , img:'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png', price:'14.99'},
            {min:[7000], max:[10000], qqIds:[628], planType:'follows', name:'IG Follows pkg 2', details:'7k - 10k new followers', increments: [10]  , img:'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png', price:'34.99', banner:'Most Popular'},
            {min:[15000], max:[25000], qqIds:[628], planType:'follows', name:'IG Follows pkg 3', details:'15k - 25k new followers', increments: [10]  , img:'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png', price:'64.99', banner:'Best Value!'},
        ]
    },
    {platform: 'YT',
        plans: [
            {min:[3000, 50], max:[5000, 100], qqIds:[1267, 777], planType:'views', name:'YT Views & Engagements pkg 1', details:'1 video w/ 3k - 5k views, 50 - 100 likes', increments: [100, 5]  , img:'https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png', price:'18.99'},
            {min:[7500, 150], max:[12000, 200], qqIds:[1267, 777], planType:'views', name:'YT Views & Engagements pkg 2', details:'1 video w/ 7.5k - 12k views, 150 - 200 likes', increments: [100, 5]  , img:'https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png', price:'34.99', banner:'Most Popular'},
            {min:[15000, 250], max:[25000, 500], qqIds:[1267, 777], planType:'views', name:'YT Views & Engagements pkg 3', details:'1 video w/ 15k - 25k views, 250 - 500 likes', increments: [100, 5]  , img:'https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png', price:'69.99', banner:'Best Value'},
            {min:[200], max:[350], qqIds:[1121], planType:'follows', name:'YT Subscribers pkg 1', details:'200 - 350 new subscribers', increments: [10]  , img:'https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png', price:'24.99'},
            {min:[500], max:[1000], qqIds:[1121], planType:'follows', name:'YT Subscribers pkg 2', details:'500 - 1000 new subscribers', increments: [10]  , img:'https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png', price:'54.99', banner:'Best Value!' },
        ]
    },
    {platform: 'TT',
        plans: [
            {min:[5000, 150], max:[10000, 250], qqIds:[1005, 1132], planType:'views', name:'TT Views & Engagements pkg 1', details:'1 post w/ 5k - 10k views, 150 - 250 likes', increments: [100,10]  , img:'https://seeklogo.com/images/T/tiktok-share-icon-black-logo-29FFD062A0-seeklogo.com.png', price:'9.99'},
            {min:[20000, 350], max:[35000, 500], qqIds:[1005, 1132], planType:'views', name:'TT Views & Engagements pkg 2', details:'1 post w/ 20k - 35k views, 350 - 500 likes', increments: [100,10]  , img:'https://seeklogo.com/images/T/tiktok-share-icon-black-logo-29FFD062A0-seeklogo.com.png', price:'24.99', banner:'Most Popular'},
            {min:[50000, 500], max:[100000, 1200], qqIds:[1005, 1132], planType:'views', name:'TT Views & Engagements pkg 3', details:'1 post w/ 50k - 100k views, 500 - 1200 likes', increments: [100,10]  , img:'https://seeklogo.com/images/T/tiktok-share-icon-black-logo-29FFD062A0-seeklogo.com.png', price:'59.99', banner:'Best Value'},
            {min:[1000], max:[3000], qqIds:[1237], planType:'follows', name:'TT Follows pkg 1', details:'1k - 3k new followers', increments: [100]  , img:'https://seeklogo.com/images/T/tiktok-share-icon-black-logo-29FFD062A0-seeklogo.com.png', price:'24.99'},
            {min:[7000], max:[10000], qqIds:[1237], planType:'follows', name:'TT Follows pkg 2', details:'7k - 10k new followers', increments: [100]  , img:'https://seeklogo.com/images/T/tiktok-share-icon-black-logo-29FFD062A0-seeklogo.com.png', price:'69.99', banner:'Most Popular'},
            {min:[15000], max:[25000], qqIds:[1237], planType:'follows', name:'TT Follows pkg 3', details:'15k - 25k new followers', increments: [100]  , img:'https://seeklogo.com/images/T/tiktok-share-icon-black-logo-29FFD062A0-seeklogo.com.png', price:'159.99', banner:'Best Value'},
        ]
    }

]