import React from 'react'
import {Grid, Typography, Button} from '@mui/material';
import Hero from './Hero';
import NavBar from './NavBar';
import PromoBar from './PromoBar';
import TrustedBrands from './TrustedBrands';
import Plans from './Plans';


function LandingPage({REACT_APP_STRIPE_PUBLISHABLE_KEY}) {
  return (

    <Grid item container xs={12} style={{position:'relative'}}>
        <NavBar/>
        <PromoBar/>
        <Hero/>
        <TrustedBrands/>
        <Plans REACT_APP_STRIPE_PUBLISHABLE_KEY={REACT_APP_STRIPE_PUBLISHABLE_KEY}/>

    </Grid>
  )
}

export default LandingPage