import React, {useState} from 'react'
import {Grid, Typography, Button, TextField} from '@mui/material';
import { GppGood } from '@mui/icons-material';
import hero from '../../images/hero.png'
import axios from 'axios';


function Hero() {
  const [url, setUrl] = useState('')
  const QQTUBE_API_KEY = 'defecd93f75b36723cd259ea0129ae4680b076a6'

  const getBalance = () => {
    axios.get("/v1-api", 
      {
        params:{ 
          api_key:QQTUBE_API_KEY, action:'funds'
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
      }
    ).then((response) => {
      setUrl("Your current balance is: $"+response.data.funds)
    }).catch((error) => {
      // console.log("Error: ", error)
    })
  }

  return (
    <Grid item xs={12} style={{height:'calc(100vh - 120px)', backgroundColor:'white'}}>
        <Grid item container xs={12} md={9} xl={7} style={{height:'100%', margin:'0px auto'}}>
            <Grid item container xs={12} md={5} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{width:'100%', height:'fit-content', padding:'0px 20px'}}>
                        <Typography variant='h1' style={{fontSize:50, fontWeight:'bold'}}>Real, Organic Social Interactions</Typography>
                        <Typography>So easy it must be magic! <br/> No bots. No fake users. Build your community with AI targeted social follower growth.</Typography>
                        <Button variant='contained' style={{height:50, borderRadius:40, marginTop:20, width:'50%'}}>Get Started</Button>
                        <div style={{display:'flex', flexFlow:'row', alignItems:'center', marginTop:20}}>
                            <GppGood style={{marginRight:10, fontSize:40, color:'green'}}/>
                            <Typography>Trusted by 8,000+ Influencers and Brands for Social Media Follower Growth</Typography>
                        </div>
                    </div>
            </Grid>

            <Grid item container xs={12} md={7} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
             
              <div style={{width:'100%', height:'fit-content', padding:'0px 20px', display:'flex', flexFlow:'column', alignItems:'flex-end'}}>
                
                <img src={hero} alt='' style={{maxWidth:600, height:'100%', objectFit:'contain'}}/>
                
                {/* <TextField
                  id="outlined-basic"
                  label="Social URL"
                  variant="outlined"
                  value={url}
                  //placeholder={time[0]}
                  onChange={(e) => {setUrl(e.target.value)}}
                  margin="normal"
                  fullWidth/>

                  <Button variant='contained' style={{height:50, borderRadius:40, marginTop:20, width:'50%'}} onClick={getBalance}>Get Balance</Button> */}

              </div>
            </Grid>

        </Grid>
    </Grid>
  )
}

export default Hero