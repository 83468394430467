import React from 'react'
import {Grid, Typography, Button} from '@mui/material';
import logo from '../../images/socialry-logo.png'


function NavBar() {
  return (
    <Grid item container xs={12} style={{height:60, backgroundColor: 'white', position:'sticky', top:0, zIndex:100}}>
        <Grid item container style={{maxWidth:1200, height:'inherit', margin:'0px auto'}}>
        <Grid item container xs={6} style={{display:'flex', alignItems:'center'}}>
            <img src={logo} alt='logo' style={{width:100, objectFit:'contain'}}/>
        </Grid>
        <Grid item container xs={6} style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
            <div style={{width:'fit-content'}}>
                <Button color='primary' style={{borderRadius:50, height:50}}>Pricing</Button>
                <Button color='primary' style={{borderRadius:50, height:50}}>Reviews</Button>
                <Button color='primary' style={{borderRadius:50, height:50}}>Recources</Button>
                <Button variant='outlined' color='primary' style={{borderRadius:50, height:50}}>Get Started</Button>
            </div>
        </Grid>
        </Grid>
    </Grid>
  )
}

export default NavBar