import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import LandingPage from './components/landingPage/LandingPage';

const {REACT_APP_STRIPE_PUBLISHABLE_KEY, REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST} = process.env
//somethin new


function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingPage  REACT_APP_STRIPE_PUBLISHABLE_KEY={REACT_APP_STRIPE_PUBLISHABLE_KEY}/> }/>
        <Route exact path="/admin" element={<LandingPage  REACT_APP_STRIPE_PUBLISHABLE_KEY={REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST}/>}/>
      </Routes>
    </Router>
  );
}

export default App;
