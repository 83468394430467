import React from 'react'
import {Grid, Typography, Button} from '@mui/material';


function PromoBar() {
  return (
    <Grid item container xs={12} style={{display:'flex', alignItems:'center', justifyContent:'center', height:60, background: 'rgb(15,94,255)',
    background: 'linear-gradient(207deg, rgba(15,94,255,1) 0%, rgba(131,58,180,1) 31%, rgba(253,29,29,1) 60%, rgba(252,215,69,1) 100%)'}}>
        <Typography style={{color:'white', fontSize:24, fontWeight:'bold'}}>60% faster than the leading social growth agencies.</Typography>

    </Grid>
  )
}

export default PromoBar