import React, {useState} from 'react'
import {Grid, Typography, Button, Card, CardActions, CardContent, CardMedia,TextField, Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		// base: {
    //   height:40,
    //   border:'1px solid grey',
		// 	iconColor: "#c4f0ff",
		// 	color: "#fff",
		// 	fontWeight: 500,
		// 	fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
		// 	fontSize: "16px",
		// 	fontSmoothing: "antialiased",
		// 	":-webkit-autofill": { color: "#fce883" },
		// 	"::placeholder": { color: "#87bbfd" }
		// },
		invalid: {
			iconColor: "#ffc7ee",
			color: "#ffc7ee"
		}
	}
}

function CheckoutForm({selectedPlan, vs, fs, setSelectedPlan, setSelectedPlatform, setViewsSelected, setFollowersSelected, QQTUBE_API_KEY, REACT_APP_STRIPE_PUBLISHABLE_KEY}) {
  const {name, price, details, min, max, qqIds, banner, increments, img} = selectedPlan
  // // console.log(selectedPlan)
  const [url, setUrl] = useState('')
  const [fullName, setName] = useState('')
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarFailure, setSnackbarFailure] = useState(false);

  // // console.log(REACT_APP_STRIPE_PUBLISHABLE_KEY.includes('test'))
  //// console.log(selectedPlan)

  const lettersOnly = (e) => {
    const re = /[A-Za-z- ]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  const validEmail = (e) => {
    const re = /[a-z0-9@-_.+~]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  // const getBalance = async () => {
  //   await axios.get("/v1-api", 
  //     {
  //       params:{ 
  //         api_key:QQTUBE_API_KEY, action:'services'
  //       },
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded"
  //       },
  //     }
  //   ).then((response) => {
  //     // console.log("Your current balance is: $"+response.data.funds)
  //     // console.log(response)
  //   }).catch((error) => {
  //     // console.log("Error: ", error)
  //   })
  // }

  const handleSuccessSnackbar = () => {
    setSnackbarSuccess(true);
  };
  const handleFailSnackbar = () => {
    setSnackbarFailure(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarSuccess(false);
    setSnackbarFailure(false);
    setSelectedPlan('');
    setSelectedPlatform('');
    setViewsSelected(false);
    setFollowersSelected(false);
    setUrl('')


  };

  const submitOrder = async (e) => {
    e.preventDefault();
    // // console.log('hello')
    // // console.log(QQTUBE_API_KEY)
    const num = Math.round(Math.floor(Math.random() * max[0]) / increments[0]) * increments[0]
    const num2 =  Math.ceil(Math.random() * max[1] / increments[1]) * increments[1]
    const num3 =  Math.ceil(Math.random() * max[1] / increments[2]) * increments[2]

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)
    })

    if(!error){
      try{
        const {id} = paymentMethod;
        const response = REACT_APP_STRIPE_PUBLISHABLE_KEY.includes('test') ?  await axios.post('https://us-central1-socialry-7b164.cloudfunctions.net/app/payment-test', {
          amount: parseInt((parseFloat(price)*100).toFixed()),
          id,
          description: "Socialry: "+name,
          email: email
        }) 
        :
        await axios.post('https://us-central1-socialry-7b164.cloudfunctions.net/app/payment', {
          amount: parseInt((parseFloat(price)*100).toFixed()),
          id,
          description: "Socialry: "+name,
          email: email
        }) 


        // console.log('response', response)
        if(response.data.success){
          // console.log('successful payment!')

         //exapmle [1432,2513,104]
         //exapmle 0,1,2

          // qqIds.forEach(async (id, idx) => {
          //   const num = Math.round(Math.floor(Math.random() * max[idx]) / increments[idx]) * increments[idx]
          //   const formdata = new FormData();
          //   formdata.append("api_key", `${QQTUBE_API_KEY}`);
          //   formdata.append("action", "add");
          //   formdata.append("url", `${url}`);
          //   formdata.append("quantity", `${num < min[idx] ? num+min[idx] : num}`);
          //   formdata.append("id_service", `${id}`);
        
          //   let res = await axios.post("/v1-api", formdata)
          //     .then((response) => {
          //       if(response.data.status !== 'error'){
          //         // console.log('qqtube success response => ', response)
          //         handleSuccessSnackbar()
          //         setSuccess(true)
          //       }else{
          //         // console.log('qqtube failure response => ', response)
          //         handleFailSnackbar()
          //       }
          //     }).catch((error) => {
          //       // console.log("Error: ", error)
          //       handleFailSnackbar()
          //     });
        
          //   return res
          // })


          if(qqIds.length === 3){
            const formdata = new FormData();
                  formdata.append("api_key", `${QQTUBE_API_KEY}`);
                  formdata.append("action", "add");
                  formdata.append("url", `${url}`);
                  formdata.append("quantity", `${num < min[0] ? num+min[0] : num}`);
                  formdata.append("id_service", `${qqIds[0]}`);
      
            const formdata2 = new FormData();
                  formdata2.append("api_key", `${QQTUBE_API_KEY}`);
                  formdata2.append("action", "add");
                  formdata2.append("url", `${url}`);
                  formdata2.append("quantity", `${num2 < min[1] ? num2+min[1] : num2}`);
                  formdata2.append("id_service", `${qqIds[1]}`);

            const formdata3 = new FormData();
                  formdata3.append("api_key", `${QQTUBE_API_KEY}`);
                  formdata3.append("action", "add");
                  formdata3.append("url", `${url}`);
                  formdata3.append("quantity", `${num3 < min[1] ? num3+min[1] : num3}`);
                  formdata3.append("id_service", `${qqIds[1]}`);
      
                  
            let res = await axios.post("/v1-api", formdata)
              .then(async (response) => {
                if(response.data.status !== 'error'){
                  // // console.log('qqtube success response => ', response)
                  // // console.log('success!')
                }
              }).catch((error) => {
                // // console.log('qqtube failure response => ', response)
                handleFailSnackbar()
              });
      
            let res2 = await axios.post("/v1-api", formdata2)
            .then(async (response) => {
              if(response.data.status !== 'error'){
                // // console.log('2nd action success!')
                // // console.log('2nd qqtube success response => ', response)
              }else{
                handleFailSnackbar()
              }
            }).catch((error) => {
              // console.log('qqtube failure response => ', response)
              handleFailSnackbar()
            });

            let res3 = await axios.post("/v1-api", formdata3)
            .then(async (response) => {
              if(response.data.status !== 'error'){
                // // console.log('3rd action success!')
                // // console.log('3rd qqtube success response => ', response)
                handleSuccessSnackbar()
                setSuccess(true)
              }else{
                handleFailSnackbar()
              }
            }).catch((error) => {
              // // console.log('qqtube failure response => ', response)
              handleFailSnackbar()
            });
        
            return (res, res2, res3);
          }else if(qqIds.length === 2){
            const formdata = new FormData();
                  formdata.append("api_key", `${QQTUBE_API_KEY}`);
                  formdata.append("action", "add");
                  formdata.append("url", `${url}`);
                  formdata.append("quantity", `${num < min[0] ? num+min[0] : num}`);
                  formdata.append("id_service", `${qqIds[0]}`);
      
            const formdata2 = new FormData();
                  formdata2.append("api_key", `${QQTUBE_API_KEY}`);
                  formdata2.append("action", "add");
                  formdata2.append("url", `${url}`);
                  formdata2.append("quantity", `${num2 < min[1] ? num2+min[1] : num2}`);
                  formdata2.append("id_service", `${qqIds[1]}`);
      
                  
            let res = await axios.post("/v1-api", formdata)
              .then(async (response) => {
                if(response.data.status !== 'error'){
                  // // console.log('qqtube success response => ', response)
                  // // console.log('success!')
                }
              }).catch((error) => {
                // // console.log('qqtube falure response => ', response)
                handleFailSnackbar()
              });
      
            let res2 = await axios.post("/v1-api", formdata2)
            .then(async (response) => {
              if(response.data.status !== 'error'){
                // // console.log('2nd action success!')
                // // console.log('2nd qqtube success response => ', response)
                handleSuccessSnackbar()
                setSuccess(true)
              }else{
                // // console.log('qqtube failure response => ', response)
                handleFailSnackbar()
              }
            }).catch((error) => {
              // // console.log("Error: ", error)
              handleFailSnackbar()
            });
        
            return (res, res2);
          }else{
            const formdata = new FormData();
            formdata.append("api_key", `${QQTUBE_API_KEY}`);
            formdata.append("action", "add");
            formdata.append("url", `${url}`);
            formdata.append("quantity", `${num < min[0] ? num+min[0] : num}`);
            formdata.append("id_service", `${qqIds[0]}`);
        
            let res = await axios.post("/v1-api", formdata)
              .then((response) => {
                if(response.status !== 'error'){
                  // // console.log('success!')
                  // // console.log('qqtube success response => ', response)
                  handleSuccessSnackbar()
                  setSuccess(true)
                }else{
                  // // console.log('qqtube failure response => ', response)
                  handleFailSnackbar()
                }
              }).catch((error) => {
                // console.log("Error: ", error)
                handleFailSnackbar()
              });
        
            return res
          }
        }

      }catch (error){
        // // console.log("Error: ", error)

      }

    }else{
      // // console.log("something went wrong: ", error.message)
    }

  }

  

  return (
   <Grid item container xs={12}>
    <Grid item container xs={12} md={6} style={{display:'flex', justifyContent:'flex-end'}}>
      <Grid item container xs={12} style={{padding:5, position:'relative', marginTop:20, maxWidth:365}}>
          <Card style={{ width: '100%', borderRadius:10, boxShadow:'-4px 12px 45px #9b9b9b8c', padding:10}}>
              <CardMedia
                  component="img"
                  height="200"
                  image={img}
                  alt="platform"
              />
              <CardContent style={{height:170}}>
                  <Typography style={{fontSize:25, fontWeight:'bold'}}>
                      ${price}
                  </Typography>
                  <ul style={{textAlign:'left'}}>
                      {details.split(',').map((x, idx) => {
                          return (<li key={idx}>{x}</li>)
                      })}
                  </ul>
              </CardContent>
          </Card>

          <div style={{width:'100%', height:'100%', position:'absolute', overflow:'hidden', zIndex:5}}>
              <div style={{width:'100%', height:'100%', position:'relative'}}>
                 {banner && 
                  <div style={{position:'absolute', zIndex:2, width:'265px', height:50,
                              background: 'linear-gradient(207deg, rgba(15,94,255,1) 0%, rgba(131,58,180,1) 31%, rgba(253,29,29,1) 60%, rgba(252,215,69,1) 100%)', transform:'rotate(326deg)', top:16, left:-50, boxShadow:'2px 2px 6px #3535358c'}}>
                      <Typography style={{marginTop:10, color:'white'}}>{banner}</Typography>
                  </div>
                 }
              </div>
          </div>
      </Grid>
    </Grid>

    <Grid item container xs={12} md={6}>
      {!success && 
      <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', padding:10, position:'relative', marginTop:20, borderRadius:10, boxShadow:'-4px 12px 45px #9b9b9b8c', height:450,  maxWidth:365}}>
        <Typography style={{fontWeight:'bold', fontSize:40}}>Checkout</Typography>
        <form onSubmit={submitOrder}>
          <TextField
            fullWidth
            label={vs ? "Post url" : 'Page url'}
            variant="outlined"
            onChange={(e)=>setUrl(e.target.value)}
            value={url}
            placeholder='https://exapmle.com/'
            required
            style={{marginBottom:20}}
            />

          <TextField
            fullWidth label="Full Name"
            variant="outlined"
            onChange={(e)=>setName(e.target.value)}
            value={fullName}
            required
            style={{marginBottom:20}}
            onKeyDown={(e) => lettersOnly(e)}
            />

          <TextField
            fullWidth label="Email"
            variant="outlined"
            onChange={(e)=>setEmail(e.target.value)}
            value={email}
            required
            style={{marginBottom:20}}
            onKeyDown={(e)=> {validEmail(e)}}
            />

          <div style={{border:'1px solid grey', borderRadius:4, padding:'15px 4px', marginBottom:20}}>
            <CardElement options={CARD_OPTIONS} />
          </div>
          <button disabled={fullName === '' || url=== '' || email===''}
          style={{height:50, width:'100%', backgroundColor:(fullName === '' || url=== '' || email==='') ? '#b2b2ff' : 'blue', borderRadius:5, border:'none', outline:'none', color:'white', fontSize:16}}>START THE MAGIC!</button>
        </form>
        {/* <Button variant='contained' fullWidth style={{height:50}} onClick={getBalance}>Start the magic</Button> */}

            
      </Grid>
      }
    </Grid>

    <Snackbar open={snackbarSuccess} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        Success, your engamengents are on the way!
      </Alert>
    </Snackbar>

    <Snackbar open={snackbarFailure} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        Oops, something went wrong... try again!
      </Alert>
    </Snackbar>


   </Grid>
  )
}

export default CheckoutForm