import React from 'react'
import {Grid, Typography, Button, Card, CardActions, CardContent, CardMedia} from '@mui/material';
import { packages } from '../PromoPackages';

function FollowerCards({selectedPlatform, setSelectedPlan, selectedPlan}) {
  return (
    <>
    {selectedPlatform === 'FB' &&
    <Grid item container xs={12} style={{display:'flex', justifyContent:'space-evenly', marginTop:30}}>

        {packages.filter((pkg) => pkg.platform === 'FB').map((x, idx) => {
            return(
                x.plans.map((plan, idx) => {
                    const {price, details, planType, name, banner} = plan
                    if(planType === 'follows'){
                        //// console.log('planType:', name)
                        return (
                            <Grid key={idx} item container xs={12} sm={5} md={4} style={{padding:5, position:'relative', marginTop:20}}>
                                <Card style={{ width: '100%', borderRadius:10, boxShadow:'-4px 12px 45px #9b9b9b8c', padding:10}}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image="https://www.hipsthetic.com/wp-content/uploads/2019/05/simple-facebook-icon.jpg"
                                        alt="green iguana"
                                    />
                                    <CardContent style={{height:170}}>
                                        <Typography style={{fontSize:25, fontWeight:'bold'}}>
                                            ${price}
                                        </Typography>
                                        <ul style={{textAlign:'left'}}>
                                            {details.split(',').map((x, idx) => {
                                                return (<li key={idx}>{x}</li>)
                                            })}
                                        </ul>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="medium" fullWidth variant='contained' style={{zIndex:23}} onClick={()=>setSelectedPlan(plan)}>Select This Plan</Button>
                                    </CardActions>
                                </Card>
    
                                <div style={{width:'100%', height:'100%', position:'absolute', overflow:'hidden', zIndex:5}}>
                                    <div style={{width:'100%', height:'100%', position:'relative'}}>
                                    {banner !== undefined &&
                                        <div style={{position:'absolute', zIndex:2, width:'265px', height:50, background: 'rgb(15,94,255)',
                                                        background: 'linear-gradient(207deg, rgba(15,94,255,1) 0%, rgba(131,58,180,1) 31%, rgba(253,29,29,1) 60%, rgba(252,215,69,1) 100%)', transform:'rotate(315deg)', top:32, left:-72, boxShadow:'2px 2px 6px #3535358c'}}>
                                            <Typography style={{marginTop:10, color:'white'}}>{banner}</Typography>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </Grid>
    
                        )
                    }
                })
            )
            })}


    </Grid>
    }

     {selectedPlatform === 'IG' &&
    <Grid item container xs={12} style={{display:'flex', justifyContent:'space-evenly', marginTop:30}}>

        {packages.filter((pkg) => pkg.platform === 'IG').map((x, idx) => {
        return(
            x.plans.map((plan, idx) => {
                const {price, details, planType, name, banner} = plan
                if(planType === 'follows'){
                    //// console.log('planType:', name)
                    return (
                        <Grid key={idx} item container xs={12} sm={5} md={4} style={{padding:5, position:'relative', marginTop:20}}>
                            <Card style={{ width: '100%', borderRadius:10, boxShadow:'-4px 12px 45px #9b9b9b8c', padding:10}}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image="https://download.logo.wine/logo/Instagram/Instagram-Logo.wine.png"
                                    alt="green iguana"
                                />
                                <CardContent style={{height:170}}>
                                    <Typography style={{fontSize:25, fontWeight:'bold'}}>
                                        ${price}
                                    </Typography>
                                    <ul style={{textAlign:'left'}}>
                                        {details.split(',').map((x, idx) => {
                                            return (<li key={idx}>{x}</li>)
                                        })}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button size="medium" fullWidth variant='contained' style={{zIndex:23}} onClick={()=>setSelectedPlan(plan)}>Select This Plan</Button>
                                </CardActions>
                            </Card>

                            <div style={{width:'100%', height:'100%', position:'absolute', overflow:'hidden', zIndex:5}}>
                                <div style={{width:'100%', height:'100%', position:'relative'}}>
                                {banner !== undefined &&
                                        <div style={{position:'absolute', zIndex:2, width:'265px', height:50, background: 'rgb(15,94,255)',
                                                        background: 'linear-gradient(207deg, rgba(15,94,255,1) 0%, rgba(131,58,180,1) 31%, rgba(253,29,29,1) 60%, rgba(252,215,69,1) 100%)', transform:'rotate(315deg)', top:32, left:-72, boxShadow:'2px 2px 6px #3535358c'}}>
                                            <Typography style={{marginTop:10, color:'white'}}>{banner}</Typography>
                                        </div>
                                        }
                                </div>
                            </div>
                        </Grid>

                    )
                }
            })
        )
        })}


    </Grid>
    }

     {selectedPlatform === 'YT' &&
    <Grid item container xs={12} style={{display:'flex', justifyContent:'space-evenly', marginTop:30}}>

        {packages.filter((pkg) => pkg.platform === 'YT').map((x, idx) => {
        return(
            x.plans.map((plan, idx) => {
                const {price, details, planType, name, banner} = plan
                if(planType === 'follows'){
                    //// console.log('planType:', name)
                    return (
                        <Grid key={idx} item container xs={12} sm={5} md={4} style={{padding:5, position:'relative', marginTop:20}}>
                            <Card style={{ width: '100%', borderRadius:10, boxShadow:'-4px 12px 45px #9b9b9b8c', padding:10}}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image="https://yt3.ggpht.com/ccPr80rfkOgsE0TMP8S8vEfP85gl12XzUGtySPFFYNMhxlQ62W7ijksmUIXv6fCBC1jBmoEqaA=s900-c-k-c0x00ffffff-no-rj"
                                    alt="green iguana"
                                />
                                <CardContent style={{height:170}}>
                                    <Typography style={{fontSize:25, fontWeight:'bold'}}>
                                        ${price}
                                    </Typography>
                                    <ul style={{textAlign:'left'}}>
                                        {details.split(',').map((x, idx) => {
                                            return (<li key={idx}>{x}</li>)
                                        })}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button size="medium" fullWidth variant='contained' style={{zIndex:23}} onClick={()=>setSelectedPlan(plan)}>Select This Plan</Button>
                                </CardActions>
                            </Card>

                            <div style={{width:'100%', height:'100%', position:'absolute', overflow:'hidden', zIndex:5}}>
                                <div style={{width:'100%', height:'100%', position:'relative'}}>
                                {banner !== undefined &&
                                        <div style={{position:'absolute', zIndex:2, width:'265px', height:50, background: 'rgb(15,94,255)',
                                                        background: 'linear-gradient(207deg, rgba(15,94,255,1) 0%, rgba(131,58,180,1) 31%, rgba(253,29,29,1) 60%, rgba(252,215,69,1) 100%)', transform:'rotate(315deg)', top:32, left:-72, boxShadow:'2px 2px 6px #3535358c'}}>
                                            <Typography style={{marginTop:10, color:'white'}}>{banner}</Typography>
                                        </div>
                                        }
                                </div>
                            </div>
                        </Grid>

                    )
                }
            })
        )
        })}

    </Grid>
    }

     {selectedPlatform === 'TT' &&
    <Grid item container xs={12} style={{display:'flex', justifyContent:'space-evenly', marginTop:30}}>

        {packages.filter((pkg) => pkg.platform === 'TT').map((x, idx) => {
        return(
            x.plans.map((plan, idx) => {
                const {price, details, planType, name, banner} = plan
                if(planType === 'follows'){
                    //// console.log('planType:', name)
                    return (
                        <Grid key={idx} item container xs={12} sm={5} md={4} style={{padding:5, position:'relative', marginTop:20}}>
                            <Card style={{ width: '100%', borderRadius:10, boxShadow:'-4px 12px 45px #9b9b9b8c', padding:10}}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image="https://is1-ssl.mzstatic.com/image/thumb/Purple112/v4/59/75/15/5975151d-255b-8334-d151-fb73c450cae7/AppIcon_TikTok-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/1200x630wa.png"
                                    alt="green iguana"
                                />
                                <CardContent style={{height:170}}>
                                    <Typography style={{fontSize:25, fontWeight:'bold'}}>
                                        ${price}
                                    </Typography>
                                    <ul style={{textAlign:'left'}}>
                                        {details.split(',').map((x, idx) => {
                                            return (<li key={idx}>{x}</li>)
                                        })}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button size="medium" fullWidth variant='contained' style={{zIndex:23}} onClick={()=>setSelectedPlan(plan)}>Select This Plan</Button>
                                </CardActions>
                            </Card>

                            <div style={{width:'100%', height:'100%', position:'absolute', overflow:'hidden', zIndex:5}}>
                                <div style={{width:'100%', height:'100%', position:'relative'}}>
                                {banner !== undefined &&
                                        <div style={{position:'absolute', zIndex:2, width:'265px', height:50, background: 'rgb(15,94,255)',
                                                        background: 'linear-gradient(207deg, rgba(15,94,255,1) 0%, rgba(131,58,180,1) 31%, rgba(253,29,29,1) 60%, rgba(252,215,69,1) 100%)', transform:'rotate(315deg)', top:32, left:-72, boxShadow:'2px 2px 6px #3535358c'}}>
                                            <Typography style={{marginTop:10, color:'white'}}>{banner}</Typography>
                                        </div>
                                        }
                                </div>
                            </div>
                        </Grid>

                    )
                }
            })
        )
        })}

    </Grid>
    }
    </>
  )
}

export default FollowerCards